.w-trawie {

  &-margin {
    margin-bottom: 100px;
  }

  &-background {
    background: url("../../../files/svg/osiagniecia/background.svg") no-repeat repeat center top;
  }

  &-text {
    &-time {
      margin-top: 10px;
      font-size: 21px;
    }
  }

  &-header {
    &-margin {
      margin-bottom: 150px;

      @media screen and (max-width: 850px) {
        margin-bottom: 100px;
      }

      @media screen and (max-width: 480px) {
        margin-bottom: 50px;
      }
    }

    @media screen and (max-width: 850px) {
      transform: scale(.70);
      transform-origin: center;
    }

    @media screen and (max-width: 480px) {
      transform: scale(.5);
      transform-origin: center;
    }
  }

  &-index {
    &-top {
      margin-top: 100px;

      @media screen and (max-width: 850px) {
        transform: scale(0.7);
        margin-top: 0;
      }
    }

    &-img {
      margin-top: 100px;

      @media screen and (max-width: 850px) {
        transform: scale(0.7);
        margin-top: 50px;
      }

      @media screen and (max-width: 480px) {
        transform: scale(0.5);
        margin-top: 150px;
      }

      @media screen and (max-width: 412px) {
        transform: scale(0.4);
        margin-top: 75px;
      }
    }

    &-background {
      background: url("../../../files/svg/coWTrawiePiszczy/background.svg") no-repeat center top;
      margin-bottom: -100px;
    }

    &-h1 {
      font-size: 64px;
    }
  }
}