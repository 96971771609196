@import "variables";
@import "reset";
@import "src/style/scss/components/navbar";
@import "src/style/scss/components/contact";
@import "components/cowtrawiepiszczy";
@import "src/style/scss/components/feedback";
@import "src/style/scss/components/footer";
@import "src/style/scss/components/galeria";
@import "src/style/scss/components/onas";
@import "src/style/scss/components/kadra";
@import "src/style/scss/components/text";
@import "src/style/scss/components/dlaciekawskich";
@import "src/style/scss/components/osiagniecia";
@import "src/style/scss/components/post";
@import "src/style/scss/components/admin/login";
@import "src/style/scss/components/admin/panel";

@font-face {
  font-family: BadComic;
  src: url("../../files/font/BadComic-Regular.ttf");
  font-style: normal;
}

.container {
  width: 100%;
  max-width: 1140px;

  @media screen and (max-width: 850px) {
    max-width: 650px;
  }

  @media screen and (max-width: 480px) {
    max-width: 410px;
  }

  @media screen and (max-width: 412px) {
    max-width: 330px;
  }
}

.container2 {
  width: 100%;
  max-width: 950px;

  @media screen and (max-width: 850px) {
    max-width: 650px;
  }

  @media screen and (max-width: 480px) {
    max-width: 410px;
  }

  @media screen and (max-width: 412px) {
    max-width: 330px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.col {
  position: relative;
  width: 100%;

  &-1 {
    flex: 0 0 10%;
    max-width: 10%;
  }

  &-08 {
    flex: 0 0 8%;
    max-width: 8%;

    @media screen and (max-width: 412px) {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }

  &-2 {
    flex: 0 0 20%;
    max-width: 20%;
  }

  &-3 {
    flex: 0 0 30%;
    max-width: 30%;
  }

  &-4 {
    flex: 0 0 40%;
    max-width: 40%;
  }

  &-5 {
    flex: 0 0 50%;
    max-width: 50%;

    @media screen and (max-width: 850px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  &-6 {
    flex: 0 0 60%;
    max-width: 60%;
  }

  &-7 {
    flex: 0 0 70%;
    max-width: 70%;
  }

  &-8 {
    flex: 0 0 80%;
    max-width: 80%;
  }

  &-9 {
    flex: 0 0 90%;
    max-width: 90%;
  }

  &-92 {
    flex: 0 0 92%;
    max-width: 92%;

    @media screen and (max-width: 412px) {
      flex: 0 0 80%;
      max-width: 80%;
    }
  }

  &-10 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  &-33 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;

    @media screen and (max-width: 412px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.center-div {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.center-div-vertical {
  align-items: center;
  display: flex;
}

.margin-top {
  &-5 {
    margin-top: 5px;
  }

  &-10 {
    margin-top: 10px;
  }

  &-15 {
    margin-top: 15px;
  }

  &-25 {
    margin-top: 25px;
  }

  &-50 {
    margin-top: 50px;
  }

  &-60 {
    margin-top: 60px;
  }

  &-70 {
    margin-top: 70px;
  }

  &-80 {
    margin-top: 80px;
  }

  &-100 {
    margin-top: 100px;
  }

  &-150 {
    margin-top: 150px;
  }

  &-200 {
    margin-top: 200px;
  }

  &-250 {
    margin-top: 250px;
  }

  &-300 {
    margin-top: 300px;
  }

  &-350 {
    margin-top: 350px;
  }
}

.margin-bottom {
  &-10 {
    margin-bottom: 10px;
  }

  &-20 {
    margin-bottom: 20px;
  }

  &-15 {
    margin-bottom: 15px;
  }

  &-35 {
    margin-bottom: 35px;
  }

  &-50 {
    margin-bottom: 50px;
  }

  &-75 {
    margin-bottom: 75px;
  }

  &-150 {
    margin-bottom: 150px;
  }

  &-100 {
    margin-bottom: 100px;
  }

  &-200 {
    margin-bottom: 200px;
  }

  &-250 {
    margin-bottom: 250px;
  }

  &-300 {
    margin-bottom: 300px;
  }

  &-350 {
    margin-bottom: 350px;
  }
}

.width {
  &-100 {
    width: 100%;
  }
}

.margin-left {
  &-5 {
    margin-left: 5px;
  }

  &-10 {
    margin-left: 10px;
  }

  &-15 {
    margin-left: 15px;
  }

  &-30 {
    margin-left: 30px;
  }

  &-55 {
    margin-left: 55px;
  }

  &-100 {
    margin-left: 100px;
  }
}

.margin-right {
  &-30 {
    margin-right: 30px;
  }

  &-100 {
    margin-right: 100px;
  }
}

.height {
  &-450 {
    height: 450px;
  }
}

.float {
  &-right {
    float: right;
  }

  &-left {
    float: left;
  }
}

textarea {
  resize: none;
}

.text {
  &-left {
    text-align: left;
  }

  &-right {
    text-align: right;
  }
}

.stick-bottom {
  display: flex;
  align-items: flex-end;
}

.display {
  &-flex {
    display: flex;
  }
}

.height {
  &-100vh {
    height: 100vh;
  }
}