.kadra {
  &-background {
    background: url("../../../files/svg/kadra/background.svg") no-repeat center top;
  }

  &-col-image {
    flex: 0 0 20%;
    max-width: 20%;

    @media screen and (max-width: 850px) {
      flex: 0 0 27%;
      max-width: 27%;
    }

    @media screen and (max-width: 480px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  &-col-text {
    flex: 0 0 80%;
    max-width: 80%;

    @media screen and (max-width: 850px) {
      flex: 0 0 73%;
      max-width: 73%;
    }

    @media screen and (max-width: 480px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  &-right {
    margin-left: auto;
    order: 2;

    @media screen and (max-width: 480px) {
      margin-left: unset;
      order: unset;
    }
  }

  &-header {
    &-margin {
      margin-bottom: 150px;

      @media screen and (max-width: 850px) {
        margin-bottom: 100px;
      }

      @media screen and (max-width: 480px) {
        margin-bottom: 50px;
      }
    }

    @media screen and (max-width: 480px) {
      transform: scale(.55);
      transform-origin: top center;
    }
  }

  &-role {
    font-size: 20px;

    font-weight: bold;

    @media screen and (max-width: 850px) {
      font-size: 16px;
    }
  }

  &-other {
    font-size: 15.5px;

  }

  &-center {
    display: grid;
    align-items: center;

    &-div {
      @media screen and (max-width: 480px) {
        text-align: center;
        justify-content: center;
        align-items: center;
        display: flex;

        margin-bottom: 100px;
      }
    }
  }

  &-float {

    &-left {
      float: left;

      @media screen and (max-width: 480px) {
        float: unset;
      }
    }

    &-right {
      float: right;

      @media screen and (max-width: 480px) {
        float: unset;
      }
    }
  }

  &-text {
    &-left {
      text-align: left;

      @media screen and (max-width: 480px) {
        text-align: center;
      }
    }

    &-right {
      text-align: right;

      @media screen and (max-width: 480px) {
        text-align: center;
      }
    }
  }
}