.footer {

  &-background {
    background-color: #5B3278;

    margin-top: -200px;
    height: 400px;
    z-index: -999;
    position: relative;

  }

  &-background2 {
    background-color: #5B3278;

    margin-top: -200px;
    height: 300px;

    &-back {
      background: url("../../../files/svg/footer/background.svg") no-repeat center top;

      margin-top: -350px;
      height: 400px;
    }
  }

  &-h1 {
    color: #A04E9D;

    font-size: 20px;
  }
}