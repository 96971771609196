.dla-ciekawskich {
  &-background {
    background: url("../../../files/svg/dlaCiekawskich/background.svg") no-repeat center top;
  }

  &-header {
    &-margin {
      margin-bottom: 150px;

      @media screen and (max-width: 850px) {
        margin-bottom: 100px;
      }

      @media screen and (max-width: 480px) {
        margin-bottom: 50px;
      }
    }

    @media screen and (max-width: 850px) {
      transform: scale(.70);
      transform-origin: center;
    }

    @media screen and (max-width: 480px) {
      transform: scale(.5);
      transform-origin: center;
    }
  }

  &-height {
    &-1 {
      height: 650px;

      @media screen and (max-width: 480px) {
        height: unset;
        margin-bottom: 75px;
      }

      @media screen and (max-width: 480px) {
        height: unset;
        margin-bottom: 35px;
      }
    }

    &-2 {
      height: 450px;

      @media screen and (max-width: 480px) {
        height: unset;
        margin-bottom: 75px;
      }

      @media screen and (max-width: 480px) {
        height: unset;
        margin-bottom: 35px;
      }
    }

    &-3 {
      height: 500px;

      @media screen and (max-width: 480px) {
        height: unset;
        margin-bottom: 75px;
      }

      @media screen and (max-width: 480px) {
        height: unset;
        margin-bottom: 35px;
      }
    }

    &-4 {
      height: 500px;

      @media screen and (max-width: 480px) {
        height: unset;
        margin-bottom: 75px;
      }

      @media screen and (max-width: 480px) {
        height: unset;
        margin-bottom: 35px;
      }
    }

    &-5 {
      height: 500px;

      @media screen and (max-width: 480px) {
        height: unset;
        margin-bottom: 150px;
      }
    }
  }
}