.feedback {
  &-background {
    background: url("../../../files/svg/feedback/background.svg") no-repeat center top;

    @media screen and (max-width: 850px) {
      background-size: cover;
    }
  }

  &-item {
    padding: 50px 35px 0px 35px;

    &-h1 {
      text-align: justify;

      font-size: 16px;

    }

    &-author {
      font-size: 24px;

      color: white;
    }

    &-triangle {
      width: 0;
      height: 0;
      border-bottom: 40px solid transparent;
      border-left: 40px solid white;
    }

    &-text {
      max-height: 208px;
      overflow-y: auto;
      padding: 2px;
    }

    &-background {
      background-color: white;
      border-radius: 40px 40px 40px 0px;
      padding: 35px 25px 35px 25px;
    }
  }
}