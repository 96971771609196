.osiagniecia {

  &-margin {
    margin-bottom: 100px;
  }

  &-background {
    background: url("../../../files/svg/osiagniecia/background.svg") no-repeat repeat center top;
  }

  &-text {
    &-time {
      margin-top: 10px;
      font-size: 21px;
    }
  }

  &-header {
    &-margin {
      margin-bottom: 150px;

      @media screen and (max-width: 850px) {
        margin-bottom: 100px;
      }

      @media screen and (max-width: 480px) {
        margin-bottom: 50px;
      }
    }

    @media screen and (max-width: 850px) {
      transform: scale(.70);
      transform-origin: center;
    }

    @media screen and (max-width: 480px) {
      transform: scale(.5);
      transform-origin: center;
    }
  }
}