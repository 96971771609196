.login {
  &-background {
    background: url("../../../../files/svg/admin/login/background.svg") no-repeat center top;
  }

  &-header {
    &-margin {
      margin-bottom: 50px;

      @media screen and (max-width: 850px) {
        margin-bottom: 0px;
      }
    }

    @media screen and (max-width: 850px) {
      transform: scale(.70);
      transform-origin: center;
    }

    @media screen and (max-width: 480px) {
      transform: scale(.5);
      transform-origin: center;
    }
  }

  &-button {

    transition: all 0.35s ease-in-out;

    &:hover {
      transform: scale(1.035);

      @media screen and (max-width: 412px) {
        transform: scale(0.9);
      }
    }

    @media screen and (max-width: 412px) {
      transform: scale(0.85);
    }
  }

  &-input {
    width: 400px;
    height: 35px;

    margin-top: 20px;
    margin-bottom: 20px;

    border: solid 1px black;
    border-radius: 20px;

    padding: 10px 10px 10px 15px;


    font-size: 18px;

    &::placeholder {
      color: black;

    }

    @media screen and (max-width: 412px) {
      width: 320px;
      height: 28px;
    }
  }
}