.navbar {

  &-logo {
    transition: all 0.55s ease-in-out;

    @media screen and (max-width: 1440px) {
      transform: scale(0.7);
    }

    @media screen and (max-width: 1024px) {
      margin-left: 50px;
    }

    @media screen and (max-width: 850px) {
      margin-top: 50px;
      margin-bottom: -100px;
      margin-left: 0px;
    }

    @media screen and (max-width: 850px) {
      margin-top: 50px;
      margin-bottom: -100px;
      margin-left: 0px;
    }
  }

  &-image {
    margin-bottom: -45px;
    margin-left: -45px;
    transition: all 0.55s ease-in-out;

    @media screen and (max-width: 1440px) {
      margin-bottom: -75px;
      transform: scale(0.7);
      transform-origin: top left;
    }

    @media screen and (max-width: 850px) {
      margin-bottom: -85px;
      transform: scale(0.65);
    }

    @media screen and (max-width: 480px) {
      margin-bottom: -118px;
      transform: scale(0.43);
      margin-left: -25px;
    }

    @media screen and (max-width: 412px) {
      margin-bottom: -130px;
      transform: scale(0.35);
      margin-left: -25px;
    }

    &:hover {
      margin-left: 0px;
    }
  }
}
