.o-nas {
  &-rekrutacja-button{
    transition: all .35s;
  }

  &-rekrutacja-button:hover{
    transform: scale(1.05);
  }

  &-background {
    background: url("../../../files/svg/oNas/background.svg") no-repeat center top;
  }

  &-h3 {
    font-size: 34px;
    text-align: justify;


    @media screen and (max-width: 480px) {
      font-size: 24px;
    }
  }

  &-header2 {
    @media screen and (max-width: 480px) {
      transform: scale(.70);
      transform-origin: bottom center;
    }
  }

  &-margin {
    margin: 30px 12px 0px 12px;

    @media screen and (max-width: 850px) {
      flex: 0 0 30%;
      max-width: 30%;

      margin: 10px 0px 0px 0px;

      transform: scale(.85);
    }

    @media screen and (max-width: 480px) {
      flex: 0 0 50%;
      max-width: 50%;

      transform: scale(.85);
    }
  }
}