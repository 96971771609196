.textCreator {
  h1 {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 10px;
  }

  p {
    font-size: 20px;
    text-align: justify;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.text {
  &-text {
    font-size: 18px;
    text-align: justify;
  }

  &-header {
    @media screen and (max-width: 480px) {
      transform: scale(.70);
      transform-origin: bottom left;
    }

    @media screen and (max-width: 400px) {
      transform: scale(.55);
      transform-origin: bottom left;
    }
  }
}
