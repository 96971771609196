.post {

  &-center {
    &-1 {
      @media screen and (max-width: 550px) {
        align-items: center;
        display: flex;
      }
    }
  }

  &-date {
    &-text {
      font-size: 19px;
    }
  }

  &-name {
    font-size: 20px;
  }

  &-frame {
    padding: 30px;
  }

  &-image {
    img {
      width: 80%;
      border-radius: 0 0 30px 30px;
      margin-bottom: 30px;
      margin-top: -20px;

      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }
  }

  &-background {
    border-radius: 30px;

    margin-bottom: 30px;
    width: 80%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    &-image {
      border-radius: 30px 30px 0 0;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

      width: 80%;
    }
  }
}