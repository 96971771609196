.panel {

  &-hr {
    width: 100%;
    border-top: solid black 1px;
  }

  &-title {
    &-height {
      height: 60px;
    }

    &-text {
      font-size: 32px;
    }
  }
}