.galeria {

  &-margin {
    margin-bottom: 100px;

    @media screen and (max-width: 850px) {
      margin-bottom: 50px;
    }

    @media screen and (max-width: 412px) {
      margin-bottom: 0px;
    }
  }

  &-login {

    &-header {
      @media screen and (max-width: 412px) {
        transform: scale(0.5);
      }
    }

    &-background {
      background: url("../../../files/svg/galeria/login/background_login.svg") no-repeat center top;
    }

    &-button {

      transition: all 0.35s ease-in-out;

      &:hover {
        transform: scale(1.035);

        @media screen and (max-width: 412px) {
          transform: scale(0.9);
        }
      }

      @media screen and (max-width: 412px) {
        transform: scale(0.85);
      }
    }

    &-input {
      width: 400px;
      height: 35px;

      margin-top: 20px;
      margin-bottom: 20px;

      border: solid 0px black;
      border-radius: 20px;

      padding: 10px 10px 10px 15px;


      font-size: 18px;

      &::placeholder {
        color: black;

      }

      @media screen and (max-width: 412px) {
        width: 320px;
        height: 28px;
      }
    }
  }

  &-photo {
    width: 550px;
    height: 375px;

    border-radius: 10px;

    margin: 10px;

    @media screen and (max-width: 1024px) {
      width: 495px;
      height: 337.5px;
    }

    @media screen and (max-width: 412px) {
      width: 357.5px;
      height: 243.75px;
    }
  }

  &-header {

    &-margin {
      margin-top: 300px;
      margin-bottom: 350px;

      @media screen and (max-width: 850px) {
        margin-bottom: 275px;
      }
    }

    &-background {
      background: url("../../../files/svg/galeria/background_header.svg") no-repeat center top;
    }
  }

  &-background {
    background: url("../../../files/svg/galeria/background.svg") no-repeat center top;
  }
}