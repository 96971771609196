.contact {

  &-map {
    width: 450px;
    height: 410px;

    @media screen and (max-width: 412px) {
      width: 350px;
      height: 310px;
    }
  }

  &-header {
    margin-top: 100px;

    @media screen and (max-width: 412px) {
      transform: scale(0.8);
      margin-top: 0px;
    }
  }

  &-float-left {
    float: left;

    @media screen and (max-width: 850px) {
      margin-top: 10px;
      float: none;
    }
  }

  &-bottom {
    margin-bottom: -35px;

    @media screen and (max-width: 850px) {
      margin-bottom: 85px;
    }
  }

  &-background {
    background: url("../../../files/svg/contact/background.svg") no-repeat center top;

    @media screen and (max-width: 850px) {
      background-size: cover;
    }
  }

  &-sendButton {
    transition: 0.5s;

    &:hover {
      transform: scale(1.05);
    }
  }

  &-item {

    &-phone {
      @media screen and (max-width: 412px) {
        padding: 25px;
      }
    }

    &-text {
      text-align: left;

      color: white;
      font-size: 12px;

      @media screen and (max-width: 850px) {
        text-align: center;
      }
    }
  }

  &-input {
    &-field {
      border: solid 0px black;
      width: 375px;
      border-radius: 20px;

      padding: 10px 10px 10px 15px;
      margin-bottom: 12px;
      height: 25px;


      @media screen and (max-width: 412px) {
        width: 275px;
      }

      &::placeholder {
        color: black;

      }
    }

    &-text {
      border: solid 0px black;
      width: 375px;
      border-radius: 20px;

      padding: 10px 10px 10px 15px;
      height: 175px;


      @media screen and (max-width: 412px) {
        width: 275px;
      }

      &::placeholder {
        color: black;

      }
    }
  }
}